import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as Sentry from '@sentry/vue';

// app locales
import enLocale from '@/i18n/en.i18n.json';
import heLocale from '@/i18n/he.i18n.json';

// components locales
import enLocaleComponents from '@clarityo/ui-components/src/i18n/en.i18n.json';
import heLocaleComponents from '@clarityo/ui-components/src/i18n/he.i18n.json';

// locales for element ui
import enLocaleEelem from 'element-ui/lib/locale/lang/en';
import heLocaleEelem from 'element-ui/lib/locale/lang/he';

// moment locales
import 'moment/locale/he';

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocaleEelem,
    ...enLocaleComponents,
    ...enLocale,
  },
  he: {
    ...heLocaleEelem,
    ...heLocaleComponents,
    ...heLocale,
  },
};

const FALLBACK_LANGUAGE_CODE = 'en';
const SUPPORTED_LANGUAGES = ['en', 'he'];

const getDefaultSystemLocale = () => {
  const navigatorLanguageCode = window.navigator.language.slice(0, 2);
  if (SUPPORTED_LANGUAGES.includes(navigatorLanguageCode)) {
    return navigatorLanguageCode;
  }

  return FALLBACK_LANGUAGE_CODE;
};

const i18n = new VueI18n({
  locale: getDefaultSystemLocale(),
  messages,
  fallbackLocale: false,
  missing: (locale, key, vm) => {
    const componentName = vm
      ? vm.$options.name ?? vm.$options._componentTag ?? 'Unknown component name'
      : 'Unknown component name';
    const message = `Missing translation key ${key} in locale ${locale} at component ${componentName}`;

    Sentry.captureMessage(message, {
      level: 'error',
    });

    const oppositeLocale = locale === 'he' ? 'en' : 'he';
    if (messages[oppositeLocale]) {
      return messages[oppositeLocale][key];
    }
  },
});
i18n.direction = () => i18n.t('direction');

export { getDefaultSystemLocale, i18n };
export default i18n;

Vue.mixin({
  computed: {
    $direction: () => i18n.direction(),
  },
});
