import { DDP } from 'meteor/ddp-client';
import { Hub, Auth } from 'aws-amplify';

import router from '@/router';
import apiConnection from '@/imports/api/api_connection';
import { setTenantBinding, clearTenantBinding } from '@/modules/tenant/compositions/tenant-operations';

Hub.listen('auth', async (data) => {
  window.Auth = Auth;
  switch (data.payload.event) {
    case 'configured':
    case 'signIn':
    case 'signOut':
    case 'tokenRefresh': {
      await getTokens().then(meteorLogin).catch(meteorLogout);
      break;
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.params.tenantId === from.params.tenantId) return next();
  const promise = to.params.tenantId ? setTenantBinding({ tenantId: to.params.tenantId }) : clearTenantBinding();
  promise.then(() => next());
});

const getTokens = () =>
  Auth.currentSession().then((session) => ({
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  }));

DDP.onReconnect(async () => {
  const { tenantId } = router.currentRoute.params;
  await Promise.all([tenantId && setTenantBinding({ tenantId }), getTokens().then(meteorLogin).catch(meteorLogout)]);
});

const meteorLogin = ({ idToken, refreshToken }) =>
  apiConnection.callPromise('cognito.login', { token: idToken, refreshToken });
const meteorLogout = () => apiConnection.callPromise('cognito.logout');
