import { Mongo } from 'meteor/mongo';

export function transformToTerm(term) {
  const t = Object.assign({}, term);
  if (t.pricingMethod === 'negotiatedPrice') {
    t.pricingMethod = 'fixedPrice';
  }
  t.priceIncludingTax = t.taxIncluded || false;
  if (t.pricingMethod === 'priceIndex') {
    t.priceIndexItemId = t.indexItemId || t.priceIndexItemId;
    t.priceIndexOffset = t.daysOffset || t.priceIndexOffset;
    t.priceIndexComission = t.commission || t.priceIndexComission;
  }
  return t;
}

export function transformFromTerm(term) {
  const t = Object.assign({}, term);
  if (t.pricingMethod === 'fixedPrice') {
    t.pricingMethod = 'negotiatedPrice';
  }
  t.taxIncluded = t.priceIncludingTax || false;
  delete t.priceIncludingTax;
  if (t.pricingMethod === 'priceIndex') {
    t.indexItemId = t.priceIndexItemId;
    t.daysOffset = t.priceIndexOffset;
    t.commission = t.priceIndexComission;
    delete t.priceIndexItemId;
    delete t.priceIndexOffset;
    delete t.priceIndexComission;
  }
  t.negotiated = false;
  return t;
}

export const Restaurants = new Mongo.Collection('tenants');
export const Suppliers = new Mongo.Collection('suppliers');
export const Items = new Mongo.Collection('items');
export const Terms = new Mongo.Collection('terms', { transform: transformToTerm });
export const PriceIndexes = new Mongo.Collection('price_indexes');
export const IndexPriceLists = new Mongo.Collection('index_price_lists');
export const ItemGroups = new Mongo.Collection('item_groups');
export const Tasks = new Mongo.Collection('tasks');
export const Users = new Mongo.Collection('users');
