import accounting from 'accounting';
import Chart from 'chart.js';

import { primary, primaryLight80, textMuted } from '@/imports/ui/colors';

// accounting.js
accounting.settings.currency.symbol = '\u{20AA}'; // New Israeli Shekel
// accounting.settings.currency.symbol = '$'; // American Dollar

// chart.js
Chart.defaults.global.defaultColor = primary;
Chart.defaults.global.defaultFontColor = textMuted;
Chart.defaults.global.title.fontSize = 16;
Chart.defaults.global.title.fontStyle = 'normal';

Chart.defaults.global.elements.point.borderColor = primary;
Chart.defaults.global.elements.point.backgroundColor = primaryLight80;
Chart.defaults.global.elements.line.borderColor = primary;
Chart.defaults.global.elements.line.backgroundColor = primaryLight80;
Chart.defaults.global.elements.rectangle.borderColor = primary;
Chart.defaults.global.elements.rectangle.backgroundColor = primaryLight80;
Chart.defaults.global.elements.arc.backgroundColor = primaryLight80;
