const ScrollManager = {
  callbacks: [],
  hasListener: false,

  subscribe(callback) {
    if (this.callbacks.indexOf(callback) === -1) {
      this.callbacks.push(callback);
    }
    this.ensureListener();
  },

  unsubscribe(callback) {
    const index = this.callbacks.indexOf(callback);
    if (index !== -1) {
      this.callbacks.splice(index, 1);
    }
  },

  scrollEvent() {
    this.callbacks.forEach((callback) => callback());
  },

  ensureListener() {
    if (!this.hasListener && this.callbacks.length > 0) {
      window.addEventListener('scroll', this.scrollEvent.bind(this), true);
      this.hasListener = true;
    } else if (this.hasListener && this.callbacks.length === 0) {
      window.removeEventListener('scroll', this.scrollEvent.bind(this), true);
      this.hasListener = false;
    }
  },
};

const captureScroll = {
  inserted(el, binding) {
    const scrollHandler = () => {
      if (typeof binding.value === 'function') {
        binding.value();
      }
    };
    el._onScrollHandler = scrollHandler;

    ScrollManager.subscribe(scrollHandler);
  },
  unbind(el) {
    ScrollManager.unsubscribe(el._onScrollHandler);
  },
};

export { captureScroll };
