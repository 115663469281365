import { Tracker } from 'meteor/tracker';
import SimpleSchema from 'simpl-schema';
import { omit } from 'ramda';

import { DOCUMENT_TYPES } from '@/modules/document/types';
import { PAYMENT_METHOD_TYPE, CREDIT_CARD_TYPE } from '@/modules/payment/types';

const getDocumentTypes = () =>
  Object.values(DOCUMENT_TYPES).filter(
    (t) =>
      ![DOCUMENT_TYPES.CONSOLIDATED_CREDIT_NOTE, DOCUMENT_TYPES.CONSOLIDATED_INVOICE, DOCUMENT_TYPES.OTHER].includes(t)
  );

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'any.required': '{{label}} is required',
      'any.unknown': '{{label}} is unknown (forbidden)',
      'array.min': 'Items must contain at least 1 item',
      'custom.unbalanced': '{{label}} does not balance ({{expected}})',
      'custom.documentUnbalanced': '{{index}}',
      'custom.unique': 'Document number is not unique',
      'number.max': '{{label}} is over the max value',
      'number.min': '{{label}} is under the minimum value',
    },
  },
});

export const generalChargesStructureParamsSchema = new SimpleSchema({
  name: Boolean,
  amount: Boolean,
});

export const ItemsParamsSchema = new SimpleSchema({
  name: Boolean,
  sku: Boolean,
  gtin: Boolean,
  price: {
    type: new SimpleSchema({
      includingTax: Boolean,
    }),
    optional: true,
  },
  netPrice: Boolean,
  quantity: Boolean,
  quantityInPackage: Boolean,
  packageQuantity: Boolean,
  discountRate: Boolean,
  discountAmount: Boolean,
  totalDiscount: Boolean,
  totalTax: Boolean,
  totalDeposits: Boolean,
  totalPackages: Boolean,
  totalAmount: {
    type: new SimpleSchema({
      includingTax: Boolean,
      includingDiscount: Boolean,
    }),
    optional: true,
  },
  reference: Boolean,
});

export const ReferencesParamsSchema = new SimpleSchema({
  documentNumber: Boolean,
  issueDate: Boolean,
  paymentDueDate: Boolean,
  netAmount: Boolean,
  balance: {
    type: new SimpleSchema({ debit: Boolean }),
    optional: true,
  },
  debitAmount: Boolean,
  creditAmount: Boolean,
  totalAmount: Boolean,
  items: { type: ItemsParamsSchema, optional: true },
});

export const ParamsSchema = new SimpleSchema({
  documentNumber: Boolean,
  allocationNumber: Boolean,
  issueDate: Boolean,
  orderReference: Boolean,
  deliveryDate: Boolean,
  netAmount: Boolean,
  taxableAmount: Boolean,
  nonTaxableAmount: Boolean,
  taxRate: Boolean,
  taxAmount: Boolean,
  totalAmount: Boolean,
  amountDue: Boolean,
  paidAmount: Boolean,
  discountRate: Boolean,
  discountAmount: Boolean,
  paymentDueDate: Boolean,
  openingBalance: Boolean,
  referencesFromDate: Boolean,
  referencesToDate: Boolean,
  paymentMethod: Boolean,
  paymentDate: Boolean,
  items: { type: ItemsParamsSchema, optional: true },
  references: { type: ReferencesParamsSchema, optional: true },
  rounding: {
    type: new SimpleSchema({ debit: Boolean }),
    optional: true,
  },
  generalCharges: { type: generalChargesStructureParamsSchema, optional: true },
});

export const getDefaultParams = (docType) => {
  const defaultParams = getSchemaDefaults(ParamsSchema);
  switch (docType) {
    case DOCUMENT_TYPES.DELIVERY_NOTE:
    case DOCUMENT_TYPES.GOODS_RETURN_NOTE:
      return {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          name: true,
          quantity: true,
        },
      };

    case DOCUMENT_TYPES.INVOICE:
    case DOCUMENT_TYPES.CREDIT_NOTE:
      return {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paymentDueDate: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          name: true,
          quantity: true,
          price: { includingTax: false },
          totalAmount: { includingTax: false, includingDiscount: false },
        },
      };

    case DOCUMENT_TYPES.BILL_INVOICE:
      return omit(['references'], {
        ...defaultParams,
        // TODO - remove  this when https://clarityo.atlassian.net/browse/DRM-1655 is deployed to production.
        documentNumber: true,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paymentDueDate: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          name: true,
          quantity: true,
          price: { includingTax: false },
          totalAmount: { includingTax: false, includingDiscount: false },
        },
      });

    case DOCUMENT_TYPES.BILL_INVOICE_RECEIPT:
      return omit(['references'], {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paidAmount: true,
        paymentMethod: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          name: true,
          quantity: true,
          price: { includingTax: false },
          totalAmount: { includingTax: false, includingDiscount: false },
        },
      });

    case DOCUMENT_TYPES.CONSOLIDATED_INVOICE:
    case DOCUMENT_TYPES.CONSOLIDATED_CREDIT_NOTE:
      return {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paymentDueDate: true,
        references: {
          ...getSchemaDefaults(ReferencesParamsSchema),
          documentNumber: true,
        },
      };

    case DOCUMENT_TYPES.RECONCILIATION_STATEMENT:
      return omit(['documentNumber'], {
        ...defaultParams,
        issueDate: true,
        openingBalance: true,
        referencesFromDate: true,
        referencesToDate: true,
        references: {
          ...getSchemaDefaults(ReferencesParamsSchema),
          documentNumber: true,
          issueDate: true,
          balance: {
            debit: true,
          },
        },
      });

    case DOCUMENT_TYPES.AGED_DEBTORS_REPORT:
      return omit(['documentNumber'], {
        ...defaultParams,
        issueDate: true,
        references: {
          ...getSchemaDefaults(ReferencesParamsSchema),
          documentNumber: true,
          issueDate: true,
          balance: {
            debit: true,
          },
        },
      });

    case DOCUMENT_TYPES.RECEIPT:
      return {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        totalAmount: true,
        paidAmount: true,
        paymentMethod: true,
        references: {
          ...getSchemaDefaults(ReferencesParamsSchema),
          documentNumber: true,
          issueDate: true,
          totalAmount: true,
        },
      };

    case DOCUMENT_TYPES.INVOICE_RECEIPT:
      return {
        ...defaultParams,
        documentNumber: true,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paidAmount: true,
        paymentMethod: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          name: true,
          quantity: true,
          price: { includingTax: false },
          totalAmount: { includingTax: false, includingDiscount: false },
        },
      };

    case DOCUMENT_TYPES.PURCHASE_ORDER:
      return omit(['references'], {
        ...defaultParams,
        documentNumber: true,
        orderReference: false,
        issueDate: true,
        rounding: { debit: false },
        netAmount: true,
        taxRate: true,
        taxAmount: true,
        totalAmount: true,
        paymentDueDate: true,
        items: {
          ...getSchemaDefaults(ItemsParamsSchema),
          sku: true,
          name: true,
          quantity: true,
          discountRate: true,
          price: { includingTax: false },
          totalAmount: { includingTax: false, includingDiscount: false },
        },
      });

    default:
      return defaultParams;
  }
};

const getDefaultByType = (type) => {
  if (type === Boolean) return false;
};

const getSchemaDefaults = (schema) =>
  schema.objectKeys().reduce(
    (params, key) => ({
      ...params,
      [key]: getDefaultByType(schema.getDefinition(key).type[0].type),
    }),
    {}
  );

export const BaseDocumentSchema = new SimpleSchema(
  {
    type: {
      type: String,
      allowedValues: getDocumentTypes(),
    },
    supplierId: String,
    issueDate: Date,
    documentNumber: String,
    allocationNumber: String,
    orderReference: String,
    deliveryDate: Date,
    structureParams: ParamsSchema,
    templateId: String,
    replicateOf: String,
  },
  { requiredByDefault: false }
);

const PriceDocumentSchema = new SimpleSchema(
  {
    generalCharges: Array,
    'generalCharges.$': new SimpleSchema({ name: String, amount: Number }, { requiredByDefault: false }),
    discountRate: Number,
    discountAmount: Number,
    rounding: Number,
    netAmount: Number,
    taxableAmount: Number,
    nonTaxableAmount: Number,
    taxRate: Number,
    taxAmount: Number,
    totalAmount: Number,
  },
  { requiredByDefault: false }
);

const ItemSchema = new SimpleSchema(
  {
    sku: String,
    name: String,
    gtin: String,
    quantity: Number,
    packageQuantity: Number,
    quantityInPackage: Number,
    price: Number,
    netPrice: Number,
    discountRate: Number,
    discountAmount: Number,
    totalDiscount: Number,
    totalTax: Number,
    totalPackages: Number,
    totalDeposits: Number,
    totalAmount: Number,
    productId: String,
    reference: String,
  },
  { requiredByDefault: false }
);

const ReferenceSchema = new SimpleSchema(
  {
    documentNumber: String,
    netAmount: Number,
    totalAmount: Number,
    debitAmount: Number,
    creditAmount: Number,
    balance: Number,
    issueDate: Date,
    paymentDueDate: Date,
    items: Array,
    'items.$': ItemSchema,
  },
  { requiredByDefault: false }
);

export const DeliveryNoteSchema = new SimpleSchema(
  {
    openingBalance: Number,
    amountDue: Number,
    items: Array,
    'items.$': ItemSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const GoodsReturnNoteSchema = new SimpleSchema(
  {
    items: Array,
    'items.$': ItemSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const InvoiceSchema = new SimpleSchema(
  {
    openingBalance: Number,
    amountDue: Number,
    items: Array,
    'items.$': ItemSchema,
    references: Array,
    'references.$': ReferenceSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const CreditNoteSchema = new SimpleSchema(
  {
    items: Array,
    'items.$': ItemSchema,
    references: Array,
    'references.$': ReferenceSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const ConsolidatedInvoiceSchema = new SimpleSchema(
  {
    references: Array,
    'references.$': ReferenceSchema,
    items: Array,
    'items.$': ItemSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const ConsolidatedCreditNoteSchema = new SimpleSchema(
  {
    references: Array,
    'references.$': ReferenceSchema,
    items: Array,
    'items.$': ItemSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const ReconciliationStatementSchema = new SimpleSchema(
  {
    type: {
      type: String,
      allowedValues: getDocumentTypes(),
    },
    supplierId: String,
    issueDate: Date,
    openingBalance: Number,
    structureParams: ParamsSchema,
    paymentDueDate: Date,
    referencesFromDate: Date,
    referencesToDate: Date,
    references: Array,
    'references.$': ReferenceSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
).extend(BaseDocumentSchema);

export const AgedDebtorsReportSchema = new SimpleSchema(
  {
    type: {
      type: String,
      allowedValues: getDocumentTypes(),
    },
    supplierId: String,
    issueDate: Date,
    openingBalance: Number,
    totalAmount: Number,
    structureParams: ParamsSchema,
    paymentDueDate: Date,
    referencesFromDate: Date,
    referencesToDate: Date,
    references: Array,
    'references.$': ReferenceSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
).extend(BaseDocumentSchema);

export const PaymentMethodSchema = new SimpleSchema(
  {
    type: { type: String, allowedValues: Object.values(PAYMENT_METHOD_TYPE) },
    creditCard: {
      type: new SimpleSchema(
        {
          type: { type: String, allowedValues: Object.values(CREDIT_CARD_TYPE) },
          number: String,
        },
        { requiredByDefault: false }
      ),
    },
    cheque: {
      type: new SimpleSchema(
        {
          number: String,
          bank: String,
          branch: String,
          account: String,
          dueDate: Date,
        },
        { requiredByDefault: false }
      ),
    },
    bankTransfer: {
      type: new SimpleSchema(
        {
          bank: String,
          branch: String,
          account: String,
        },
        { requiredByDefault: false }
      ),
    },
  },
  { requiredByDefault: false }
);

export const ReceiptSchema = new SimpleSchema(
  {
    totalAmount: Number,
    paidAmount: Number,
    paymentMethod: { type: PaymentMethodSchema, optional: true },
    references: Array,
    'references.$': ReferenceSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
).extend(BaseDocumentSchema);

export const InvoiceReceiptSchema = new SimpleSchema(
  {
    paidAmount: Number,
    paymentDate: Date,
    paymentMethod: { type: PaymentMethodSchema, optional: true },
    references: Array,
    'references.$': ReferenceSchema,
    items: Array,
    'items.$': ItemSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const BillInvoiceSchema = new SimpleSchema(
  {
    openingBalance: Number,
    amountDue: Number,
    items: Array,
    'items.$': ItemSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const BillInvoiceReceiptSchema = new SimpleSchema(
  {
    paidAmount: Number,
    paymentDate: Date,
    paymentMethod: { type: PaymentMethodSchema, optional: true },
    items: Array,
    'items.$': ItemSchema,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export const PurchaseOrderSchema = new SimpleSchema(
  {
    openingBalance: Number,
    amountDue: Number,
    items: Array,
    'items.$': ItemSchema,
    paymentDueDate: Date,
  },
  { tracker: Tracker, requiredByDefault: false }
)
  .extend(BaseDocumentSchema)
  .extend(PriceDocumentSchema);

export default {
  deliveryNote: DeliveryNoteSchema,
  goodsReturnNote: GoodsReturnNoteSchema,
  invoice: InvoiceSchema,
  creditNote: CreditNoteSchema,
  consolidatedInvoice: ConsolidatedInvoiceSchema,
  consolidatedCreditNote: ConsolidatedCreditNoteSchema,
  reconciliationStatement: ReconciliationStatementSchema,
  agedDebtorsReport: AgedDebtorsReportSchema,
  receipt: ReceiptSchema,
  invoiceReceipt: InvoiceReceiptSchema,
  billInvoice: BillInvoiceSchema,
  billInvoiceReceipt: BillInvoiceReceiptSchema,
  purchaseOrder: PurchaseOrderSchema,
};
