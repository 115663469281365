import Vue from 'vue';
import moment from 'moment';
import ElementUI from 'element-ui';
import VueTippy, { TippyComponent } from 'vue-tippy';
import Contextmenu from 'vue-contextmenujs/dist/contextmenu.common.js';
import Components from '@clarityo/ui-components';
import i18n from './i18n';
import PageLayout from '@/imports/ui/components/page_layout.vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';

// Plugins
Vue.use(ElementUI, { i18n: (key, options) => i18n.t(key, options) });
Vue.use(Components);
Vue.use(VueTippy);
Vue.use(VueCroppie);
Vue.use(Contextmenu);

// Components
Vue.component('page-layout', PageLayout);
Vue.component('tippy', TippyComponent);

// Filters
const DEFAULT_LIMIT_SIZE = 25;

Vue.filter('date', (value) => (value ? moment.utc(value).format('LL') : ''));
Vue.filter('moFormatDate', (value, format) => (value ? moment.utc(value).format(format) : ''));
Vue.filter('limit', (value, size = DEFAULT_LIMIT_SIZE) =>
  value.length > size ? `${value.substr(0, size - 3)}...` : value
);
