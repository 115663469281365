import './defaults';
import './accounts';
import './vue_globals';
import './meteor-vue';

import moment from 'moment';
import { Meteor } from 'meteor/meteor';
import Vue, { provide } from 'vue';
import Amplify from 'aws-amplify';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { DefaultApolloClient } from '@vue/apollo-composable';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// Init Sentry
if (config.VUE_APP_SENTRY_DNS) {
  Sentry.init({
    Vue,
    dsn: config.VUE_APP_SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing({ routingInstrumentation: Sentry.vueRouterInstrumentation(router) }),
    ],
    tracesSampleRate: 1.0,
    // Keep native Vue error logs in devtools console.
    logErrors: true,
    environment: config.NODE_ENV,
  });
}

import config from '@/config';
import router from '@/router';
import i18n from './i18n';
import store from '@/imports/ui/store';
import { apolloProvider } from '@/plugins/apollo-client';
import './meteor';

const app = new Vue({
  router,
  store,
  i18n,
  setup() {
    provide(DefaultApolloClient, apolloProvider.defaultClient);
  },
  apolloProvider,
  data: {
    onLine: navigator.onLine,
  },
  watch: {
    $direction: {
      handler(newVal) {
        document.documentElement.dir = newVal;
        document.documentElement.lang = this.$i18n.locale;
      },
      immediate: true,
    },
    '$i18n.locale': {
      handler(newVal) {
        moment.locale(newVal);
      },
      immediate: true,
    },
    onLine(online) {
      if (!online) {
        this.offlineWarning = this.$notify.error({
          title: this.$t('internetStatus.offline'),
          duration: 0,
          showClose: false,
        });
      } else {
        this.offlineWarning?.close();
        this.$notify.success({
          title: this.$t('internetStatus.online'),
          message: this.$t('internetStatus.pleaseRefresh'),
        });
      }
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
  },
  render: (createElement) => createElement('router-view'),
});

if (config.VUE_APP_SENDBIRD_APP_ID) {
  Vue.prototype.$sendbird = SendbirdChat.init({
    appId: config.VUE_APP_SENDBIRD_APP_ID,
    modules: [new GroupChannelModule()],
  });
}

Amplify.configure({
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: config.VUE_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: config.VUE_APP_USER_POOL_CLIENT_ID,
});

// Start app
Meteor.startup(() => {
  app.$mount('#app');
});
